export const addElementEvents = {
	GridTextBox: 'text_element',
	GridImage: 'image_element',
	GridGallery: 'gallery_element',
	GridVideo: 'video_element',
	GridSocialIcons: 'social_icons_element',
	GridMap: 'map_element',
	GridForm: 'form_element',
	GridButton: 'button_element',
	GridStripeButton: 'stripe_button_element',
	GridInstagramFeed: 'instagram_feed_element',
};
