<template>
	<div
		ref="gridContainer"
		class="block-grid"
		data-qa="builder-section-grid"
		:style="computedStyles"
		:class="{
			'block-grid__add-element-active' : eventInfo && eventInfo.addElementActive,
			'has-zero-m-vertical-padding': isBlockHovered && (isMobileView || isMobileScreen)
		}"
		@mouseover.self="$emit('hover-background', true)"
		@mouseout.self="$emit('hover-background', false)"
		@mousedown.self="eventInfo ? '' : setEditMode(false)"
		@dragenter.prevent="onDragEnter"
		@dragover.prevent="onDragEnter"
		@dragleave.prevent="onDragLeave"
		@drop.prevent="onDrop($event)"
	>
		<Transition name="fade">
			<div
				v-show="showLayoutHelpers"
				class="block-grid__center-line"
			/>
		</Transition>

		<HeightController
			v-if="isBlockHovered && (isMobileView || isMobileScreen)"
			:value="topPadding"
			@start-resizing="onHeightControllerStart"
			@stop-resizing="topPadding = $event, onHeightControllerStop()"
		/>

		<!-- Use wrapper div for mobile or sorting breaks -->
		<template
			v-if="!(isMobileScreen || isMobileView)"
		>
			<BlockGridItem
				v-for="(componentId) of blockComponents"
				:id="componentId"
				:key="`${componentId}-element-desktop`"
				:ref="`item-${componentId}`"
				:data="website.components[componentId]"
				class="block-grid__item"
				:use-m-margin="!isBlockHovered"
				:style="{ '--element-z-index': data.zindexes.indexOf(componentId) + 1 }"
				@mousedown="startMovingGridElement($event, componentId)"
				@mouseenter="hoveredElementId = componentId"
				@mouseleave="hoveredElementId = null"
				@update-height="changeElementHeight($event)"
				@set-height="changeElementHeight($event, true)"
				@contextmenu="openContextMenu($event, componentId)"
			/>
		</template>
		<Draggable
			v-else
			animation="150"
			ghost-class="dragged-element"
			handle=".sortable"
			:delay-on-touch-only="true"
			:prevent-on-filter="false"
			filter=".height-controller, .gridResizer__outline, .right, .left"
			fallback-axis="y"
			:force-fallback="true"
			:delay="200"
			:fallback-tolerance="1"
			@start="handleDragStart"
			@sort="handleDragSort"
			@end="handleDragEnd"
		>
			<div
				v-for="(componentId) of blockComponents"
				:key="`${componentId}-wrapper`"
				class="sortable block-grid__item-wrapper"
				@mousedown.self="selectCurrentBlock"
			>
				<div
					class="block-grid__item"
					:class="{ 'is-selected': selectedElementId === componentId }"
					:style="alignmentStyles(componentId)"
					@mousedown="startMovingGridElement($event, componentId)"
					@mouseenter="hoveredElementId = componentId"
					@mouseleave="hoveredElementId = null"
				>
					<BlockGridItem
						:id="componentId"
						:key="`${componentId}-element`"
						:ref="`item-${componentId}`"
						:data="website.components[componentId]"
						class="block-grid__item--inner"
						:use-m-margin="!isBlockHovered"
						:style="{ '--element-z-index': data.zindexes.indexOf(componentId) + 1 }"
						@start-resizing="onHeightControllerStart"
						@stop-resizing="onHeightControllerStop"
					/>
					<OverlayPill
						class="block-grid__item-pill"
						:text="$options.elementTitles[website.components[componentId].type]"
					/>
				</div>
				<HeightController
					v-if="(isMobileView || isMobileScreen)
						&& isBlockHovered
						&& componentId !== blockComponents[blockComponents.length - 1]"
					:key="`${componentId}-margin`"
					class="height-controller"
					:value="getElementBottomMargin(componentId)"
					@start-resizing="onHeightControllerStart"
					@stop-resizing="updateElementBottomMargin(componentId, $event), onHeightControllerStop()"
				/>
			</div>
		</Draggable>
		<EmptyBlockWarning v-if="showEmptyBlock" />

		<Transition name="fade">
			<BlockGridPattern
				v-show="showLayoutHelpers"
				:id="blockId"
				:column-width="columnWidth"
				:data="data"
			/>
		</Transition>

		<BlockGridElementInfoOverlay :info="hoveredElementInfo" />

		<BlockGridResizer
			:position="gridResizerPosition"
			:resize-visible="!eventInfo"
			@trigger-resize="startResizingGridElement"
		/>
		<HeightController
			v-if="isBlockHovered && (isMobileView || isMobileScreen)"
			:value="bottomPadding"
			@stop-resizing="bottomPadding = $event, onHeightControllerStop()"
			@start-resizing="onHeightControllerStart"
		/>
	</div>
</template>

<script>
import * as Sentry from '@sentry/browser';
import Draggable from 'vuedraggable-axis';
import {
	mapState,
	mapGetters,
	mapActions,
	mapMutations,
} from 'vuex';

import EventLogApi from '@/api/EventLogApi';
import BlockGridItem from '@/components/block-grid-item/BlockGridItem.vue';
import BlockGridElementInfoOverlay from '@/components/block-grid/BlockGridElementInfoOverlay.vue';
import BlockGridPattern from '@/components/block-grid/BlockGridPattern.vue';
import BlockGridResizer from '@/components/block-grid/BlockGridResizer.vue';
import EmptyBlockWarning from '@/components/block-grid/EmptyBlockWarning.vue';
import HeightController from '@/components/block-grid/HeightController.vue';
import OverlayPill from '@/components/block-grid/OverlayPill.vue';
import { useContextMenu } from '@/components/context-menu/useContextMenu';
import { useGridElementContextMenu } from '@/components/context-menu/useGridElementContextMenu';
import i18n from '@/i18n/setup';
import { cloneDeep } from '@/utils/object';
import { parseCSSSides } from '@/utils/parseCSSSides';

import { addElementEvents } from './BlockGrid.events';

const elementTitles = {
	GridTextBox: i18n.t('common.text'),
	GridImage: i18n.t('common.image'),
	GridGallery: i18n.t('common.gallery'),
	GridVideo: i18n.t('common.video'),
	GridSocialIcons: i18n.t('common.socialIcons'),
	GridMap: i18n.t('common.map'),
	GridForm: i18n.t('common.form'),
	GridButton: i18n.t('common.button'),
	GridStripeButton: i18n.t('common.stripeCheckout'),
	GridInstagramFeed: i18n.t('common.instagramFeed'),
};

export default {
	elementTitles,
	components: {
		Draggable,
		BlockGridItem,
		BlockGridPattern,
		BlockGridElementInfoOverlay,
		BlockGridResizer,
		HeightController,
		EmptyBlockWarning,
		OverlayPill,
	},
	props: {
		data: {
			type: Object,
			required: true,
		},
		blockId: {
			type: String,
			required: true,
		},
		activeEventInfo: {
			type: Object,
			default: () => ({}),
		},
		isBlockHovered: {
			type: Boolean,
			default: false,
		},
		selectedElementId: {
			type: String,
			default: '',
		},
	},
	setup() {
		const { setMouseEvent } = useContextMenu();
		const { rightClickedElementId } = useGridElementContextMenu();

		return {
			setMouseEvent,
			rightClickedElementId,
		};
	},
	data() {
		return {
			eventInfo: null,
			hoveredElementId: null,
			resizeObserver: null,
			resizeObserverGrid: null,
		};
	},
	computed: {
		...mapState([
			'website',
			'currentElementId',
		]),
		...mapState('gui', [
			'isEditMode',
			'isMobileView',
			'isMobileScreen',
		]),
		...mapGetters(['currentElement']),
		// TEMPORARY. If component is missing, log Sentry event and return only existing components.
		blockComponents() {
			const missingComponents = this.data.components.filter((componentId) => !this.website.components[componentId]);

			if (missingComponents.length) {
				Sentry.captureException(new Error(`ERROR: Missing components: ${missingComponents.join(',')}`), {
					tags: {
						json: this.website,
						errorType: 'Invalid data.json',
					},
				});

				return this.data.components.filter((componentId) => this.website.components[componentId]);
			}

			return this.data.components;
		},
		computedStyles() {
			return { '--current-grid-height': this.gridHeightInRows };
		},
		showEmptyBlock() {
			return this.website.blocks[this.blockId].components.length === 0
				&& !this.data.background.current
				&& !(this.eventInfo && this.eventInfo.addElementActive);
		},
		showLayoutHelpers() {
			return (this.activeEventInfo
				&& (this.activeEventInfo.blockId === this.blockId || this.eventInfo))
				&& !this.isMobileScreen && !this.isMobileView;
		},
		isCurrentlyEditingTextElement() {
			return this.isEditMode && this.currentElement.type === 'GridTextBox';
		},
		topPadding: {
			set(newValue) {
				const padding = parseCSSSides(this.data.settings.styles['m-block-padding'] || '0');

				padding.top = `${newValue}px`;
				const updatedPadding = Object.values(padding).join(' ');

				this.$emit('update-mobile-padding', updatedPadding);

				this.setBlockData({
					blockId: this.blockId,
					data: { settings: { styles: { 'm-block-padding': updatedPadding } } },
					skipHistory: false,
				});
			},
			get() {
				return Number.parseInt(parseCSSSides(this.data.settings.styles['m-block-padding'] || '0').top, 10);
			},
		},
		bottomPadding: {
			set(newValue) {
				const padding = parseCSSSides(this.data.settings.styles['m-block-padding'] || '0');

				padding.bottom = `${newValue}px`;
				const updatedPadding = Object.values(padding).join(' ');

				this.$emit('update-mobile-padding', updatedPadding);

				this.setBlockData({
					blockId: this.blockId,
					data: { settings: { styles: { 'm-block-padding': updatedPadding } } },
					skipHistory: false,
				});
			},
			get() {
				return Number.parseInt(parseCSSSides(this.data.settings.styles['m-block-padding'] || '0').bottom, 10);
			},
		},
		columnWidth() {
			if (!this.$refs.gridContainer) {
				return 0;
			}

			return Number.parseFloat(getComputedStyle(this.$refs.gridContainer)['grid-template-columns'].split(' ')[3], 0);
		},
		hoveredElementInfo() {
			if (this.activeEventInfo?.elementId) {
				const position = this.website.components[this.activeEventInfo.elementId].settings.styles.position.split('/');

				return {
					y1: position[0],
					x1: position[1],
					y2: position[2],
					x2: position[3],
					color: this.activeEventInfo.outlineColor,
				};
			}

			if (!this.hoveredElementId
				|| this.eventInfo
				|| !this.website.components[this.hoveredElementId]
				|| this.hoveredElementId === this.currentElementId
				|| this.isMobileView
				|| this.isMobileScreen) {
				return null;
			}

			const position = this.website.components[this.hoveredElementId].settings.styles.position.split('/');

			return {
				title: this.$options.elementTitles[this.website.components[this.hoveredElementId].type] || 'Item',
				y1: position[0],
				x1: position[1],
				y2: position[2],
				x2: position[3],
			};
		},
		gridResizerPosition() {
			if (this.isMobileView || this.isMobileScreen) {
				return null;
			}

			if (this.eventInfo) {
				return this.eventInfo.gridResizerPosition;
			}

			if (this.blockComponents.includes(this.currentElementId)) {
				/**
				 * * currentElement getter is somehow not always reactive here
				 * * and position gets out of sync after misc actions
				 * * e.g. adding a section and then moving an element in that section
				 */
				const currentElement = this.$store.state
					.website.components[this.$store.state.currentElementId];

				return currentElement.settings.styles.position;
			}

			return null;
		},
		gridHeightInRows() {
			if (!this.$refs.gridContainer) {
				return 0;
			}

			return getComputedStyle(this.$refs.gridContainer)['grid-template-rows'].split(' ').length;
		},
	},
	watch: {
		// assign default background color only when `showEmptyBlock` turns from true to false
		showEmptyBlock(currentState, previousState) {
			if (previousState && !currentState && !this.data.background.current) {
				this.setBlockData({
					blockId: this.blockId,
					data: {
						background: {
							color: 'var(--colors-light)',
							current: 'color',
						},
					},

				});
			}
		},
	},
	mounted() {
		// columnWidth uses ref to calculate, but it isnt available before mount
		this.$recompute('columnWidth');
		this.$recompute('gridHeightInRows');

		// Watch body size
		this.resizeObserver = new ResizeObserver(() => {
			this.$recompute('columnWidth');
		});
		this.resizeObserver.observe(document.body);

		// Watch grid size
		this.resizeObserverGrid = new ResizeObserver(() => {
			this.$recompute('columnWidth');
			this.$recompute('gridHeightInRows');
			this.$emit('rows-changed', this.gridHeightInRows);
		});
		this.resizeObserverGrid.observe(this.$refs.gridContainer);
	},
	beforeDestroy() {
		this.resizeObserver.disconnect();
		this.resizeObserverGrid.disconnect();
	},
	methods: {
		...mapActions('gui', ['setEditMode']),
		...mapActions(['setCurrentElement']),
		...mapMutations([
			'addElement',
			'setElementData',
			'setBlockData',
			'unsetCurrentElement',
		]),
		handleDragStart() {
			this.$emit('lock-hovered-block', true);
			this.unsetCurrentElement();
		},
		handleDragSort(event) {
			const items = cloneDeep(this.website.blocks[this.blockId].components);
			const oldItem = items[event.oldIndex];

			items.splice(event.oldIndex, 1);
			items.splice(event.newIndex, 0, oldItem);

			this.setBlockData({
				blockId: this.blockId,
				data: { components: items },
			});
		},
		handleDragEnd() {
			this.$emit('lock-hovered-block', false);
		},
		openContextMenu(e, id) {
			if (this.isCurrentlyEditingTextElement) {
				return;
			}

			e.preventDefault();
			this.setMouseEvent(e);
			this.rightClickedElementId = id;
		},
		// util to check if currently dragged element has required custom event property (elementId)
		validateDragDrop(event) {
			const canBeDragDropped = event.dataTransfer.getData('elementId');

			if (!canBeDragDropped) {
				event.preventDefault();
			}

			return canBeDragDropped;
		},
		// Mobile spacing control
		getElementBottomMargin(componentId) {
			// Use bottom margin if available
			if (this.website.components[componentId].settings.styles['m-element-margin']) {
				return Number.parseInt(
					parseCSSSides(
						this.website.components[componentId].settings.styles['m-element-margin'],
					).bottom,
					10,
				);
			}
			// Fallback to 0

			return 0;
		},
		updateElementBottomMargin(componentId, spacing) {
			this.setElementData({
				elementId: componentId,
				data: { settings: { styles: { 'm-element-margin': `0 0 ${spacing}px 0` } } },
				skipHistory: false,
			});
		},
		onHeightControllerStart() {
			this.$emit('lock-hovered-block', true);
			this.$emit('set-edit-control-visibility', false);
		},
		onHeightControllerStop() {
			this.$emit('lock-hovered-block', false);
			this.$emit('set-edit-control-visibility', true);
		},
		// Grid control
		pxToRows(value, roundToUpper = false) {
			// Get values
			const rowSize = Number.parseInt(this.data.settings.styles['row-size'], 10);
			const rowGap = Number.parseInt(this.data.settings.styles['row-gap'], 10);
			const increaseHeight = roundToUpper ? 0.5 : 0;
			// Calculate

			return Math.round((value / (rowSize + rowGap)) + increaseHeight);
		},
		pxToColumns(value) {
			// Calculate
			return Math.round(value / (this.columnWidth + Number.parseInt(this.data.settings.styles['column-gap'], 10)));
		},
		changeElementHeight(componentId, allowLowerHeight = false) {
			if (this.eventInfo || this.isMobileView || this.isMobileScreen) {
				return;
			}

			const element = this.$refs[`item-${componentId}`][0].$refs.element.$el;
			const newHeightInRows = this.pxToRows(element.scrollHeight, true);
			// Converts grid-area: 0/1/2/3 to an array [0,1,2,3]
			const originalPosition = this.website.components[componentId].settings.styles.position.split('/').map((coordinate) => Number.parseInt(coordinate, 10));
			const addRows = newHeightInRows - (originalPosition[2] - originalPosition[0]);

			if (addRows > 0) {
				originalPosition[2] += addRows;
			}

			const newBottom = allowLowerHeight
				? newHeightInRows + originalPosition[0] : originalPosition[2];

			// eslint-disable-next-line max-len, vue/max-len
			this.website.components[componentId].settings.styles.position = `${originalPosition[0]}/${originalPosition[1]}/${newBottom}/${originalPosition[3]}`;
		},
		shouldPreventGridLayoutChange(event) {
			// button 2 is right click, prevents opening context menu from starting drag/resize action
			return this.isMobileScreen || this.isMobileView || event.button === 2;
		},
		startResizingGridElement(event, direction) {
			if (this.shouldPreventGridLayoutChange(event)) {
				return;
			}

			const element = this.$refs[`item-${this.currentElementId}`][0].$el;

			this.eventInfo = {
				x: event.clientX,
				y: event.clientY,
				id: this.currentElementId,
				element,
				gridResizerPosition: null,
				direction,
				originalHeight: element.clientHeight,
				originalWidth: element.clientWidth,
				// TODO: Check if this can be moved to util
				originalPosition: this.website.components[this.currentElementId].settings.styles.position.split('/').map((coordinate) => Number.parseInt(coordinate, 10)),
			};
			this.eventInfo.gridResizerPosition = this.calculateGridElementPosition(event);
			this.$emit('set-edit-control-visibility', false);
			this.eventInfo.element.style.setProperty('--event-background-color', 'var(--grey-800-02)');

			window.addEventListener('mousemove', this.resizeGridElement);
			window.addEventListener('mouseup', this.stopResizingGridElement);
		},
		resizeGridElement(event) {
			const newPosition = {
				y1: this.eventInfo.originalPosition[0],
				x1: this.eventInfo.originalPosition[1],
				y2: this.eventInfo.originalPosition[2],
				x2: this.eventInfo.originalPosition[3],
			};

			let eventMouseMovedX = event.clientX - this.eventInfo.x;
			let eventMouseMovedY = event.clientY - this.eventInfo.y;

			// When grabbed by left resize thingie, negative value increases width
			const xIsInverted = this.eventInfo.direction.toLowerCase().includes('left') ? 1 : -1;

			// Limit width to minimum 1 column
			if (this.eventInfo.originalWidth - eventMouseMovedX * xIsInverted < this.columnWidth) {
				eventMouseMovedX = (this.eventInfo.originalWidth - this.columnWidth) * xIsInverted;
			}

			// When grabbed by left resize thingie, negative value increases width
			const yIsInverted = this.eventInfo.direction.toLowerCase().includes('top') ? 1 : -1;
			// Limit height to minimum 1 row
			const rowSize = Number.parseInt(this.data.settings.styles['row-size'], 10);

			if (this.eventInfo.originalHeight - eventMouseMovedY * yIsInverted < rowSize) {
				eventMouseMovedY = (this.eventInfo.originalHeight - rowSize) * yIsInverted;
			}

			// Limit top resize to grid top
			const rowGap = Number.parseInt(this.data.settings.styles['row-gap'], 10);
			const maxResizeToTop = (newPosition.y1 - 1) * (rowSize + rowGap);

			if (eventMouseMovedY * yIsInverted < 0) {
				eventMouseMovedY = Math.max(-maxResizeToTop, eventMouseMovedY);
			}

			const movedCols = this.pxToColumns(eventMouseMovedX);
			const movedRows = this.pxToRows(eventMouseMovedY);

			switch (this.eventInfo.direction) {
			case 'right':
				newPosition.x2 += movedCols;
				// TODO: Check if styles can be easily bound
				this.eventInfo.element.style.setProperty('--width-x', `${eventMouseMovedX}px`);
				break;
			case 'left':
				newPosition.x1 += movedCols;
				this.eventInfo.element.style.setProperty('--width-x', `${-eventMouseMovedX}px`);
				this.eventInfo.element.style.setProperty('--moved-x', `${eventMouseMovedX}px`);
				break;
			case 'bottom':
				newPosition.y2 += movedRows;
				this.eventInfo.element.style.setProperty('--width-y', `${eventMouseMovedY}px`);
				break;
			case 'top':
				newPosition.y1 += movedRows;
				this.eventInfo.element.style.setProperty('--width-y', `${-eventMouseMovedY}px`);
				this.eventInfo.element.style.setProperty('--moved-y', `${eventMouseMovedY}px`);
				break;
			case 'topLeft':
				newPosition.y1 += movedRows;
				newPosition.x1 += movedCols;
				this.eventInfo.element.style.setProperty('--width-y', `${-eventMouseMovedY}px`);
				this.eventInfo.element.style.setProperty('--width-x', `${-eventMouseMovedX}px`);
				this.eventInfo.element.style.setProperty('--moved-y', `${eventMouseMovedY}px`);
				this.eventInfo.element.style.setProperty('--moved-x', `${eventMouseMovedX}px`);
				break;
			case 'topRight':
				newPosition.y1 += movedRows;
				newPosition.x2 += movedCols;
				this.eventInfo.element.style.setProperty('--width-y', `${-eventMouseMovedY}px`);
				this.eventInfo.element.style.setProperty('--width-x', `${eventMouseMovedX}px`);
				this.eventInfo.element.style.setProperty('--moved-y', `${eventMouseMovedY}px`);
				break;
			case 'bottomLeft':
				newPosition.y2 += movedRows;
				newPosition.x1 += movedCols;
				this.eventInfo.element.style.setProperty('--width-y', `${eventMouseMovedY}px`);
				this.eventInfo.element.style.setProperty('--width-x', `${-eventMouseMovedX}px`);
				this.eventInfo.element.style.setProperty('--moved-x', `${eventMouseMovedX}px`);
				break;
			case 'bottomRight':
				newPosition.y2 += movedRows;
				newPosition.x2 += movedCols;
				this.eventInfo.element.style.setProperty('--width-y', `${eventMouseMovedY}px`);
				this.eventInfo.element.style.setProperty('--width-x', `${eventMouseMovedX}px`);
				break;
			default:
				break;
			}

			const columnCount = Number.parseInt(this.data.settings.styles['column-count'], 10);

			// Limit right position to max column count
			newPosition.x2 = Math.max(Math.min(columnCount + 5, newPosition.x2), 2);
			// Limit left position to min column count
			newPosition.x1 = Math.min(Math.max(1, newPosition.x1), newPosition.x2 - 1);
			// Limit top position to  min first row
			newPosition.y1 = Math.max(1, newPosition.y1);
			this.eventInfo.gridResizerPosition = newPosition;
		},
		stopResizingGridElement() {
			window.removeEventListener('mouseup', this.stopResizingGridElement);
			window.removeEventListener('mousemove', this.resizeGridElement);
			this.eventInfo.element.style.setProperty('--width-x', '0');
			this.eventInfo.element.style.setProperty('--width-y', '0');
			this.eventInfo.element.style.setProperty('--moved-x', '0');
			this.eventInfo.element.style.setProperty('--moved-y', '0');
			this.eventInfo.element.style.setProperty('--event-background-color', '');

			// eslint-disable-next-line max-len, vue/max-len
			const newPositionStringified = `${this.eventInfo.gridResizerPosition.y1}/${this.eventInfo.gridResizerPosition.x1}/${this.eventInfo.gridResizerPosition.y2}/${this.eventInfo.gridResizerPosition.x2}`;

			this.setElementData({
				elementId: this.eventInfo.id,
				data: { settings: { styles: { position: newPositionStringified } } },
				skipHistory: false,
			});
			this.changeElementHeight(this.eventInfo.id);
			this.eventInfo = null;
			this.$emit('set-edit-control-visibility', true);
		},
		startMovingGridElement(event, componentId) {
			if (this.shouldPreventGridLayoutChange(event)) {
				return;
			}

			if (this.isCurrentlyEditingTextElement) {
				return;
			}

			// TODO: Improve gridresizerposition naming
			this.eventInfo = {
				x: event.clientX,
				y: event.clientY,
				id: componentId,
				element: this.$refs[`item-${componentId}`][0].$el,
				gridResizerPosition: null,
			};
			this.eventInfo.element.style.setProperty('--event-background-color', 'var(--grey-800-02');
			this.$emit('set-edit-control-visibility', false);
			window.addEventListener('mousemove', this.moveGridElement);
			window.addEventListener('mouseup', this.stopMovingGridElement);
		},
		moveGridElement(event) {
			// TODO: Check if styles can be bound
			this.eventInfo.element.style.setProperty('--moved-x', `${event.clientX - this.eventInfo.x}px`);
			this.eventInfo.element.style.setProperty('--moved-y', `${event.clientY - this.eventInfo.y}px`);
			this.eventInfo.gridResizerPosition = this.calculateGridElementPosition(event);
		},
		snapPositionToGrid(position) {
			const newPosition = position;
			// Limit to center grid
			let leftOvershot = false;
			let rightOvershot = false;

			if (newPosition.x1 < 3) {
				const overshot = Math.abs(newPosition.x1 - 3);

				newPosition.x1 += overshot;
				newPosition.x2 += overshot;
				leftOvershot = true;
			}

			const columnCount = Number.parseInt(this.data.settings.styles['column-count'], 10);

			if (newPosition.x2 > columnCount + 3) {
				const overshot = newPosition.x2 - columnCount - 3;

				newPosition.x2 -= overshot;
				newPosition.x1 -= overshot;
				rightOvershot = true;
			}

			// Check left overshot again, overshooting on the right may make left side too big
			if (newPosition.x1 < 3) {
				const overshot = Math.abs(newPosition.x1 - 3);

				newPosition.x1 += overshot;
				newPosition.x2 += overshot;
				leftOvershot = true;
			}

			if (leftOvershot && rightOvershot) {
				newPosition.x1 = 3;
				newPosition.x2 = columnCount + 3;
			}

			if (newPosition.y1 < 1) {
				const overshot = Math.abs(1 - newPosition.y1);

				newPosition.y1 += overshot;
				newPosition.y2 += overshot;
			}

			return newPosition;
		},
		calculateGridElementPosition(event) {
			// Calculate position
			const movedCols = this.pxToColumns(event.clientX - this.eventInfo.x);
			const movedRows = this.pxToRows(event.clientY - this.eventInfo.y);
			const originalPosition = this.website.components[this.eventInfo.id].settings.styles.position.split('/').map((coordinate) => Number.parseInt(coordinate, 10));

			let newPosition = {
				y1: originalPosition[0] + movedRows,
				x1: originalPosition[1] + movedCols,
				y2: originalPosition[2] + movedRows,
				x2: originalPosition[3] + movedCols,
			};

			/*
			 * Checks if element is moved to the sides (dont snap if moving up and down),
			 * good for full widht elements
			 */
			if (movedCols !== 0) {
				newPosition = this.snapPositionToGrid(newPosition);
			}

			// Dont first row be negative
			if (newPosition.y1 < 1) {
				const overshot = Math.abs(1 - newPosition.y1);

				newPosition.y1 += overshot;
				newPosition.y2 += overshot;
			}

			return newPosition;
		},
		stopMovingGridElement(event) {
			const newPosition = this.calculateGridElementPosition(event);
			const newPositionStringified = `${newPosition.y1}/${newPosition.x1}/${newPosition.y2}/${newPosition.x2}`;

			// Set position
			this.setElementData({
				elementId: this.eventInfo.id,
				data: { settings: { styles: { position: newPositionStringified } } },
				skipHistory: false,
			});
			// Reset event
			window.removeEventListener('mouseup', this.stopMovingGridElement);
			window.removeEventListener('mousemove', this.moveGridElement);
			this.eventInfo.element.style.setProperty('--moved-x', '');
			this.eventInfo.element.style.setProperty('--moved-y', '');
			this.eventInfo.element.style.setProperty('--event-background-color', '');
			this.eventInfo = null;
			this.$emit('set-edit-control-visibility', true);
		},
		getMousePositionOnGrid(event) {
			// Calculate position in grid
			const containerBoundingBox = this.$refs.gridContainer.getBoundingClientRect();
			const row = this.pxToRows(event.clientY - containerBoundingBox.top);

			// Calculate grid width
			let gridWidth = Number.parseInt(this.data.settings.styles['grid-width'], 10);

			if (gridWidth > this.$refs.gridContainer.clientWidth) {
				const contentPadding = Number.parseInt(this.data.settings.styles['content-padding'], 10);

				gridWidth = this.$refs.gridContainer.clientWidth - contentPadding;
			}

			const gridStartsAt = (containerBoundingBox.width - gridWidth) / 2;

			// Start from grid column
			const column = 3 + this.pxToColumns((event.clientX - gridStartsAt));

			return {
				column,
				row,
			};
		},
		onDrop(event) {
			if (!this.validateDragDrop(event)) {
				return;
			}

			this.$emit('drag-status-change', false);
			// Get event data
			const id = event.dataTransfer.getData('elementId');
			const content = JSON.parse(event.dataTransfer.getData('content'));
			const width = Number.parseInt(event.dataTransfer.getData('width'), 10);
			const height = Number.parseInt(event.dataTransfer.getData('height'), 10);
			const eventData = JSON.parse(event.dataTransfer.getData('eventData'));

			const {
				column,
				row,
			} = this.getMousePositionOnGrid(event);

			const position = this.snapPositionToGrid({
				x1: column,
				x2: column + width,
				y1: row,
				y2: row + height,
			});

			content.settings.styles.position = `${position.y1}/${position.x1}/${position.y2}/${position.x2}`;

			this.addElement({
				blockId: this.blockId,
				elementId: id,
				element: content,
			});

			this.$nextTick(() => {
				const elementBoxReference = this.$refs[`item-${id}`][0];
				// Element ref requires vue component
				const elementReference = elementBoxReference.$children[0];

				this.setCurrentElement({
					elementId: id,
					elementBoxRef: elementBoxReference.$el,
					elementRef: elementReference,
				});
				this.$emit('set-edit-control-visibility', true);
			});
			this.eventInfo = null;

			this.logAddElementEvent(eventData);
		},
		selectCurrentBlock() {
			if (!this.hoveredElementId) {
				this.unsetCurrentElement();
			}
		},
		onDragEnter(event) {
			if (!this.validateDragDrop(event)) {
				return;
			}

			this.$emit('drag-status-change', true);

			// Error handling
			if (!this.eventInfo) {
				this.$emit('set-edit-control-visibility', false);
			}

			this.eventInfo = { addElementActive: true };
		},
		onDragLeave(event) {
			if (!this.validateDragDrop(event)) {
				return;
			}

			this.$emit('drag-status-change', false);
			this.$emit('set-edit-control-visibility', true);
			this.eventInfo = null;
		},
		alignmentStyles(componentId) {
			return {
				'align-self': `${this.website.components[componentId]?.settings.styles['m-align-self']}`,
				// TODO: Move to mapper
				width: this.website.components[componentId]?.settings.styles['m-width'] || '100%',
			};
		},
		logAddElementEvent(eventData) {
			const {
				from,
				type,
			} = eventData;

			EventLogApi.logEvent({
				eventName: `builder.${addElementEvents[type]}.add`,
				eventProperties: { from },
			});
		},
	},
};
</script>
<style lang="scss" scoped>
@import "@user/components/block-grid/BlockGrid.scss";

.dragged-element {
	::v-deep {
		background-color: rgba($grey-200, 0.5);
		transition: 0.15s;
	}
}

.block-grid {
	$this: &;

	user-select: none;

	// Prevent children from triggering dragLeave event
	&__add-element-active {
		::v-deep * {
			pointer-events: none;
		}
	}

	&__center-line {
		$line-width: 2px;

		position: absolute;
		top: 0;
		bottom: 0;
		justify-self: center;
		width: 0;
		pointer-events: none;
		border-right: $line-width/2 dashed $accent-two;
		border-left: $line-width/2 dashed $accent-two;
	}

	&__item-pill {
		display: none;

		#{$this}__item:not(.is-selected) #{$this}__item--inner:hover + & {
			display: block;
		}
	}

	&__item {
		$grid-item: &;

		position: relative;

		&-wrapper {
			display: flex;
			flex-direction: column;

			&:last-of-type {
				#{$grid-item}--inner {
					margin-bottom: 0;
				}
			}
		}
	}
}

.fade {
	&-enter-active,
	&-leave-active {
		transition: $side-toolbar-transition-duration opacity;
	}

	&-enter,
	&-leave-to {
		opacity: 0;
	}
}
</style>
