<template>
	<div
		v-if="position"
		class="gridResizer"
		:style="computedStyles"
	>
		<div class="gridResizer__outline" />
		<template
			v-if="resizeVisible"
			@mousedown.stop
		>
			<div
				v-for="triggerPosition in $options.positions"
				:key="triggerPosition"
				class="gridResizer__handle"
				:class="triggerPosition"
				@mousedown="$emit('trigger-resize', $event, triggerPosition)"
			>
				<div :class="`${triggerPosition}__dot`" />
			</div>
		</template>
	</div>
</template>

<script>
const positions = [
	'top',
	'right',
	'bottom',
	'left',
	'topLeft',
	'topRight',
	'bottomLeft',
	'bottomRight',
];

export default {
	positions,
	props: {
		position: {
			type: [
				Object,
				String,
			],
			default: null,
		},
		resizeVisible: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		computedStyles() {
			const { position } = this;

			if (typeof position === 'object') {
				return `--position: ${position.y1}/${position.x1}/${position.y2}/${position.x2};`;
			}

			return `--position: ${position}`;
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'BlockGridResizer.scss';

.gridResizer {
	position: relative;
	grid-area: var(--position);
}
</style>
