<template>
	<div
		class="height-controller"
		:style="computedStyles"
		:class="{ 'is-active' : eventInfo }"
		@mousedown="startResizing"
	>
		<div
			class="height-controller__background"
		>
			<template v-if="eventInfo && eventInfo.yCurrent">
				<div class="height-controller__line-ending" />
				<div class="height-controller__middle-line" />
				<div class="height-controller__value z-body-small">
					{{ computedValue }} px
				</div>
				<div class="height-controller__middle-line" />
				<div class="height-controller__line-ending" />
			</template>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		value: {
			type: Number,
			default: 0,
		},
		linearity: {
			type: Number,
			default: 1.5,
		},
	},
	data() {
		return { eventInfo: null };
	},
	computed: {
		computedValue() {
			return Math.max(this.eventInfo?.yCurrent
				? this.value
				+ Math.floor(((this.eventInfo.yCurrent - this.eventInfo.yStart) / this.linearity))
				: this.value, 0);
		},
		computedStyles() {
			return { '--height-controller-height': `${this.computedValue}px` };
		},
	},
	methods: {
		startResizing(event) {
			this.eventInfo = { yStart: event.clientY };
			window.addEventListener('mousemove', this.resizeElement);
			window.addEventListener('mouseup', this.stopResizing);
			this.$emit('start-resizing');
		},
		resizeElement(event) {
			this.eventInfo = {
				...this.eventInfo,
				yCurrent: event.clientY,
			};
		},
		stopResizing() {
			window.removeEventListener('mouseup', this.stopResizing);
			window.removeEventListener('mousemove', this.resizeElement);
			this.$emit('stop-resizing', this.computedValue);
			this.eventInfo = null;
		},
	},
};
</script>

<style lang="scss" scoped>

.height-controller {
	// How much to expand height controller outwards
	$heightControllerExpansion: 12px;

	position: relative;
	z-index: z-index('controls--margin-control');
	height: var(--height-controller-height);
	cursor: row-resize;
	transition: background-color 0.2s $easing-standard;

	&:hover {
		background-color: rgba($accent-two, 0.1);
	}

	&.is-active {
		background-color: rgba($accent-two, 0.2);
	}

	&__background {
		position: absolute;
		top: $heightControllerExpansion / -2;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: calc(var(--height-controller-height) + #{$heightControllerExpansion});
		padding: $heightControllerExpansion / 2 0;
	}

	&__line-ending {
		position: relative;
		display: block;
		flex: 0 1 2px;
		width: 20px;
		background-color: $accent-two;
		border-radius: 2px;
	}

	&__value {
		padding: 2px 4px;
		margin: 0;
		color: $light;
		background-color: $accent-two;
		border-radius: 2px;
	}

	&__middle-line {
		flex: 0 100 100%;
		border-left: 1px dashed $accent-two;
	}
}
</style>
