<template>
	<div
		ref="elementBox"
		class="block-grid-item"
		:style="variablesString + innerBackgroundValue"
		:class="{
			'is-current': currentElementId === id,
			'is-editing': isEditMode,
			'use-m-margin': useMMargin
		}"
		v-on="$listeners"
		@click.prevent
		@mousedown="setCurrentElement(id, $refs.elementBox, $refs.element)"
		@dblclick="setEditModeCheck()"
	>
		<Component
			:is="data.type"
			:id="id"
			ref="element"
			:data="data"
			@hook:mounted="listenForSizeChanges(), $recompute('width'), $recompute('parentWidth')"
			@hook:beforeDestroy="listenForSizeChangesStop"
		/>
		<BlockGridMobileResizer
			v-if="useMobileResizer"
			:element-alignment="elementAlignment"
			:value="width"
			:max-width="parentWidth"
			@start-resizing="onStartResizing"
			@resize="onResize"
			@stop-resizing="onStopResizing"
		/>
	</div>
</template>

<script>
import {
	mapState,
	mapActions,
	mapGetters,
	mapMutations,
} from 'vuex';

import BlockGridMobileResizer from '@/components/block-grid/BlockGridMobileResizer.vue';
import { useInlineCSSVariables } from '@/use/useInlineCSSVariables';
import { cloneDeep } from '@/utils/object';

const AUTO_EXPAND_ELEMENTS = [
	'GridTextBox',
	'GridSocialIcons',
	'GridForm',
];

const AUTO_RESIZE_ELEMENTS = [
	'GridInstagramFeed',
	'GridGallery',
];

const AUTO_RESIZEABLE_ELEMENTS = [
	...AUTO_RESIZE_ELEMENTS,
	...AUTO_EXPAND_ELEMENTS,
];

const NON_RESIZABLE_MOBILE_ELEMENTS = [
	'GridButton',
	'GridStripeButton',
];

export default {
	name: 'BlockGridItem',
	components: {
		GridButton: () => import('@/components/grid-components/button/GridButtonProviderBuilder.vue'),
		GridStripeButton: () => import('@/components/grid-components/stripeButton/GridStripeButtonProviderBuilder.vue'),
		GridMap: () => import('@/components/grid-components/map/GridMapProviderBuilder.vue'),
		GridVideo: () => import('@/components/grid-components/video/GridVideoProviderBuilder.vue'),
		GridImage: () => import('@/components/grid-components/image/GridImageProviderBuilder.vue'),
		GridTextBox: () => import('@/components/grid-components/textBox/GridTextBoxProviderBuilder.vue'),
		GridForm: () => import('@/components/grid-components/form/GridFormProviderBuilder.vue'),
		GridInstagramFeed: () => import('@/components/grid-components/instagramFeed/GridInstagramFeedProviderBuilder.vue'),
		GridSocialIcons: () => import('@/components/grid-components/socialIcons/GridSocialIconsProviderBuilder.vue'),
		GridGallery: () => import('@/components/grid-components/gallery/GridGalleryProviderBuilder.vue'),
		BlockGridMobileResizer,
	},
	props: {
		id: {
			type: String,
			required: true,
		},
		data: {
			type: Object,
			required: true,
		},
		useMMargin: {
			type: Boolean,
			default: true,
		},
	},
	setup(props) {
		const { variablesString } = useInlineCSSVariables(props);

		return { variablesString };
	},
	data() {
		return { resizeObserver: null };
	},
	computed: {
		...mapState(['currentElementId']),
		...mapState('gui', [
			'isEditMode',
			'isMobileView',
			'isMobileScreen',
		]),
		...mapGetters(['currentElement']),
		showMobileResizer() {
			return !NON_RESIZABLE_MOBILE_ELEMENTS.includes(this.currentElement.type);
		},
		innerBackgroundValue() {
			const { innerBackground } = this.data;

			if (!innerBackground) {
				return null;
			}

			const currentBackground = innerBackground[innerBackground.current];

			if (innerBackground.current === 'image') {
				const backgroundValue = `--gridItemInnerBackground:url(${currentBackground});`;
				const backgroundOverlay = `--gridItemInnerBackgroundOverlayOpacity: ${innerBackground['overlay-opacity']};`;

				return 'overlay-opacity' in innerBackground ? `${backgroundValue}${backgroundOverlay}` : backgroundValue;
			}

			return `--gridItemInnerBackground:${currentBackground}`;
		},
		// Mobile resizing
		parentWidth() {
			return this.$refs.elementBox.parentNode.parentNode.clientWidth;
		},
		width() {
			return this.$refs.elementBox?.clientWidth ?? 0;
		},
		useMobileResizer() {
			return this.currentElementId === this.id && (this.isMobileView || this.isMobileScreen);
		},
		elementAlignment() {
			return this.showMobileResizer
				? this.data.settings.styles['m-align-self'] || 'flex-start' : 'none';
		},
	},
	/*
	 * We need this watcher to automatically update the height of BlockGridItem
	 * when increasing the inner padding of a form element
	 */
	watch: {
		'data.settings.styles.gridItemInnerPadding': function forceResizeElementHeight() {
			this.$emit('update-height', this.id);
		},
	},
	methods: {
		...mapActions('gui', ['setEditMode']),
		...mapMutations([
			'setElementData',
			'pushElementDataToHistory',
		]),
		listenForSizeChanges() {
			if (!AUTO_RESIZEABLE_ELEMENTS.includes(this.data.type)) {
				return;
			}

			const element = this.$refs.element.$el;

			this.resizeObserver = new ResizeObserver(() => {
				// Only work on current element so screen resize doesn't change element positions
				if (this.currentElementId !== this.id) {
					return;
				}

				const shouldUpdateHeight = this.$refs.elementBox.clientHeight < element.scrollHeight;

				if (shouldUpdateHeight) {
					this.$emit('update-height', this.id);

					return;
				}

				if (AUTO_RESIZE_ELEMENTS.includes(this.data.type)) {
					this.$emit('set-height', this.id);
				}
			});
			this.resizeObserver.observe(element);
		},
		listenForSizeChangesStop() {
			if (!AUTO_RESIZEABLE_ELEMENTS.includes(this.data.type)) {
				return;
			}

			if (this.resizeObserver) {
				this.resizeObserver.disconnect();
			}

			this.resizeObserver = null;
		},
		setCurrentElement(elementId, elementBoxReference, elementReference) {
			if (elementId === this.currentElementId) {
				return;
			}

			this.setEditMode(false);
			this.$store.commit('setCurrentElement', {
				elementId,
				elementBoxRef: elementBoxReference,
				elementRef: elementReference,
			});
			this.$emit('element-selected');
		},
		setEditModeCheck() {
			if (this.isEditMode) {
				return;
			}

			this.setEditMode(true);
		},
		// Mobile resizing
		onStartResizing() {
			this.$emit('start-resizing');
			this.currentElementBeforeEdit = cloneDeep(this.data);
		},
		onResize(size) {
			this.setElementData({
				skipHistory: true,
				elementId: this.id,
				data: { settings: { styles: { 'm-width': `${size}px` } } },
			});
		},
		onStopResizing(size) {
			const widthInPercent = (size / this.parentWidth) * 100;

			this.setElementData({
				skipHistory: true,
				elementId: this.id,
				data: { settings: { styles: { 'm-width': `${widthInPercent}%` } } },
			});
			this.pushElementDataToHistory({
				elementId: this.id,
				oldData: this.currentElementBeforeEdit,
			});
			this.currentElementBeforeEdit = null;
			this.$emit('stop-resizing');
			this.$recompute('width');
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@user/components/block-grid-item/BlockGridItem";

.block-grid-item {
	width: calc(100% + var(--width-x, 0));
	// TODO: rename to height
	height: calc(100% + var(--width-y, 0));
	background-color: var(--event-background-color);
	transition: background 0.2s ease-in;
	transform: translate3d(var(--moved-x, 0), var(--moved-y, 0), 0);
	transform-origin: bottom right;

	&:not(.is-editing) {
		cursor: move;
	}

	&.is-editing {
		* {
			// Safari fix
			-webkit-user-select: text;
			user-select: text;
		}
	}
}

@include zyro-media($media-grid) {
	.block-grid-item {
		overflow: visible;

		&:not(.is-editing) {
			cursor: move;
		}

		&:not(.is-current):hover {
			outline: 2px solid $accent-two;
		}

		// Margin on last element is removed in blockgrid.vue
		&.use-m-margin {
			margin: var(--m-element-margin);
		}
	}
}
</style>
